<template>
  <div class="servidores-olt-placa-uplink">
    <h1 class="titulo-card fs-4">Servidores / Gerenciamento OLT / Placa uplink</h1>
    <div v-if="(placa)" class="form-group card-principal">
      <div class="row justify-content-between">
        <div class="col mb-3">
          <button v-on:click="$router.go(-1)" class="btn btn-outline-primary">
            <font-awesome-icon :icon="['fas', 'arrow-left']" fixed-width class="me-1"/>Voltar
          </button>
        </div>
      </div>
      <div class="form-group">
        <!-- <div class="d-flex justify-content-between">
          <div class="input-group mb-3">
            <input
                v-on:keyup.enter="handlePesquisa()"
                type="text"
                class="form-control"
                placeholder="Pesquisar"
                aria-label="Pesquisar"
                aria-describedby="button-addon2">
            <button v-on:click="handlePesquisa()" class="btn btn-outline-secondary" type="button" id="button-addon2">
              <font-awesome-icon :icon="['fas', 'search']" fixed-width class="me-1"/>
            </button>
          </div>
        </div> -->
        <div v-if="placa" class="row border-bottom">
          <p class="mb-0"><strong>Slot:</strong> {{placa.slot}}</p>
          <p class="mb-0"><strong>Placa uplink:</strong> {{placa.endereco}}</p>
          <p class="mb-0"><strong>Tipo:</strong> {{placa.tipo_config}} / {{placa.tipo_hardware}}</p>
          <p class="mb-0"><strong>Status:</strong> {{placa.status_str}}</p>
        </div>

        <div class="d-flex">
          <table class="table table-sm-pc table-striped table-hover">
            <thead>
              <tr>
                <th width="65">Porta</th>
                <th>Endereço</th>
                <th>VLAN's tagged</th>
                <th>VLAN's untagged</th>
                <th>Status</th>
              </tr>
              </thead>
              <tbody>
                <tr v-for="porta in placa.portas" :key="porta" @click="navegaParaPorta(porta)" class="cursor-pointer">
                  <td scope="row">{{porta}}</td>
                  <td>{{enderecoPorta(porta)}}</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                </tr>
              </tbody>
          </table>
        </div>
        <!-- <Paginador v-model="paginador" @change-paginacao="onChangePaginacao"/> -->
      </div>
    </div>
  </div>
</template>

<script>
// import Paginador from '@/components/Paginador.vue'
import api from '@/modules/api'
import dialogo from '@/modules/dialogo'
import loading from '@/modules/loading'
// import clone from '@/modules/clone'
// import model from '@/model'

export default {
  name: 'ServidoresOLTPlacaUplink',

  components: {
    // Paginador,
  },

  data() {
    return {
      oltID: undefined,
      placaID: undefined,
      placa: undefined,
    }
  },

  methods: {
    init() {
      loading(true);

      api.get(`/servidores/olt/${this.oltID}/placas/${this.placaID}`).then(res => {
        loading(false);
        this.placa = res.data;
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    navegaParaPorta(porta) {
      const oltID = this.oltID;
      const placaID = this.placaID;

      this.$router.push({ path: `/configuracoes/servidores/olts/${oltID}/placa-uplink/${placaID}/porta/${porta}` });
    },

    enderecoPorta(porta) {
      return `${this.placa.endereco}/${porta}`;
    },
    /*
    handlePesquisa() {
      loading(true);

      api.get(`/servidores/olt/${this.oltID}/placas?${this.paginador.requestParams}&orderBy[slot]=ASC`).then(res => {
        loading(false);
        this.placas = res.data.items;
        this.paginador.pagina = res.data.page;
        this.paginador.totalItens = res.data.totalItems;
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    onChangePaginacao() {
      this.handlePesquisa();
    },

    placaValida(placa) {
      const homologados = ['tpUplink'];

      switch (true) {
        case (!placa || !homologados.includes(placa.tipo) || placa.status_sgm !== 'ssPronta'):
          return false;

        default:
          return true;
      }
    },

    cssRowPlaca(placa) {
      if (!this.placaValida(placa)) {
        return ['text-muted-2'];
      }
      
      return ['text-info', 'cursor-pointer'];
    },
    */
  },

  created() {
    const oltID = +this.$route.params.oltID;
    const placaID = +this.$route.params.placaID;

    if (!oltID || !placaID) {
      this.$router.push({ path: `/configuracoes/servidores` });
      return;
    }

    this.oltID = oltID;
    this.placaID = placaID;
    this.init();
  },
}
</script>
